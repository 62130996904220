#{$app}- {
    &blog {
        cursor: pointer;

        &:last-child {
            & #{$app}-card {
                margin-bottom: 0;
            }
        }
        
        &:first-child {
            @media (min-width: 768px) {
                max-width: 100% !important;
                flex: 0 0 100% !important;   
            }

            & #{$app}-card {
                @media (min-width: 768px) {
                    display: flex;
                    flex-wrap: wrap;
                }

                &__{
                    &image {
                        @media (min-width: 768px) {
                            width: 50%;
                        }
                    }
                    &content {
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;

                        @media (min-width: 768px) {
                            width: 50%;
                        }
                    }
                }
            }
        }

        &__ {
            &detail {
                &- {
                    &para span {
                        font-size: var(--fs-text-sm) !important;

                        & strong {
                            font-size: var(--fs-para);
                            line-height: calc(var(--fs-para) + 1rem);
                            font-weight: 700;
                            display: inline-block;
                            margin-top: 20px;
                            margin-bottom: 10px;
                            display: block;

                            & em {
                                font-style: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}