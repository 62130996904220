#{$app}- {
    &banner {
        &__ {
            &content {
                margin-bottom: 30px;

                @media (min-width: 768px) {
                    margin-bottom: 0px;
                }
            }
            &title {
                font-size: var(--fs-title);
                line-height: calc(var(--fs-title) + 12px);
                font-weight: 700;
                color: $color-primary;

                @media (min-width: 768px) {
                    margin-top: calc(-20px - var(--section-spacing));
                }
                
                // @media (min-width: 992px) {
                //     margin-top: calc(-100px - var(--section-spacing));
                // }

                // @media (min-width: 1200px) {
                //     margin-top: calc(-100px - var(--section-spacing));
                // }

                br {
                    @media (max-width: 1100px) {
                        display: none;
                    }
                }

                &-- {
                    &grey {
                        color: $color-secondary;
                    }
                    &white {
                        color: #ffffff;
                    }
                }
                &- {
                    &medium {
                        font-size: var(--fs-title);
                        line-height: calc(var(--fs-title) + 2px);
                    }
                }
            }
            &subtitle {
                font-size: var(--fs-title);
                line-height: calc(var(--fs-title) + 12px);
                font-weight: 700;
            }
            &para {
                &-- {
                    &grey {
                        color: #636363;
                    }
                    &white {
                        color: #ffffff;
                    }
                }

                br {
                    @media (max-width: 1100px) {
                        display: none;
                    }
                }

            }
        }
    }
}