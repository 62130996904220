@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');


$app: ".sh";

//FONT SIZE
// $fs-title: 60px - 10px;
// $fs-subtitle: 40px - 10px;
// $fs-para: 28px - 4px;
// $fs-text: 24px - 4px;

// $fs-text-sm: 20px - 2px;

//FONT FAMILY
$open-sans: 'Open Sans', sans-serif;
$nunito: 'Nunito', sans-serif;

// COLORS
$color-primary: #007A87;
$color-secondary: #313131;
$color-tertiary: #F1CF25;
$color-background-green: #F8F9F9;
$color-background-blue: #F6FBFE;
$color-para: #313131;
$color-border-color: #f0f0f0;

//SPACING
$section-spacing: 100px;
$title-wrap-spacing :80px;

// :root {
//     --fs-title: 32px;
//     --fs-subtitle: 26px;
//     --fs-title-medium: 24px;
//     --fs-para: 20px;
//     --fs-text: 18px;

//     --fs-text-sm: 16px;

//     //SPACING
//     --section-spacing: 50px;
//     --title-wrap-spacing :30px;
// }

// @media (min-width: 768px) {
//     :root {
//         --fs-title: 36px;
//         --fs-subtitle: 28px;
//         --fs-title-medium: 26px;
//         --fs-para: 24px;
//         --fs-text: 20px;

//         --fs-text-sm: 18px;

//         //SPACING
//         --section-spacing: 60px;
//         --title-wrap-spacing :40px;
//     }
// }

// @media (min-width: 992px) {
//     :root {
//         --fs-title: 40px;
//         --fs-subtitle: 28px;
//         --fs-title-medium: 30px;
//         --fs-para: 22px;
//         --fs-text: 20px;

//         --fs-text-sm: 18px;

//         //SPACING
//         --section-spacing: 80px;
//         --title-wrap-spacing :50px;
//     }
// }

// @media (min-width: 1400px) {
    :root {
        --fs-title: 2.6rem; // 46px
        --fs-subtitle: 1.75rem; //30px
        --fs-title-medium: 2rem; // 32px
        --fs-para: 1.4rem; //28px
        --fs-text: 1.4rem; //24px

        --fs-text-sm: 1.125rem; //18px

        //SPACING
        --section-spacing: 6.25rem; //100px
        --title-wrap-spacing : 3.75rem; //60px
        --title-spacing : 1.25rem; //20px
    }
// }

@media (max-width: 1400px) {
    :root {
        --fs-text-sm: 1.25rem; //18px
    }
}