#{$app}- {
    &mentor {
        cursor: pointer;

        &__ {
            &slide {
                // cursor: pointer;

                &.sh-pointer {
                    cursor: pointer;
                }
                
                &- {
                    &image {
                        width: 19rem;
                        height: 19rem;
                        margin: 0 auto;
                        border-radius: 100%;
                        background-color: lightgrey;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        filter: grayscale(1);
                    }
                    &details {
                        min-height: 108px;
                        font-family: $nunito;
                        padding: 20px 25px;
                        border-radius: 8px 8px 30px 8px;
                        background: #fff;
                        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);
                        border: 1px solid #f0f0f0;
                        
                        &- {
                            &left {
                                width: calc(100% - 70px);
                                padding-right: 20px;
                            }
                            &right {
                                width: 70px;
                            }
                        }
                    }
                    
                    &name {
                        font-size: var(--fs-text);
                        line-height: calc(var(--fs-text) + 1rem);
                        font-weight: 600;
                    }
                    &position {
                        font-size: var(--fs-text-sm);
                        line-height: calc(var(--fs-text-sm) + 1rem);
                        color: $color-primary;
                        min-height: 60px;
                        @media (min-width:980px) {
                            min-height: 80px;
                        }
                    }
                    &company {
                        max-height: 35px;
                        max-width: 70px;
                        width: auto !important;
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
    }
}