#{$app}- {
    &about {
        &__ {
            &section {
                background: rgba($color: #007A87, $alpha: 0.06);
                padding-top: var(--section-spacing);
            }
            &banner {

                &- {
                    &background {
                        top: 0;
                        width: 100%;
                        height:100%;

                        & img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    &inwrap {
                        padding: 30px 0 120px;
                        
                        @media (min-width: 768px) {
                            padding: 120px 0;
                        }
                    }
                    &left {
                        width: 100%;
                        margin-bottom: 15px;
                        // width: calc(645px - 8.333%);

                        @media (min-width: 768px) {
                            width: calc(230px);
                        }
                        @media (min-width: 992px) {
                            width: calc(460px - 8.333%);
                        }
                        @media (min-width: 1200px) {
                            width: calc(570px - 8.333%);
                        }
                        @media (min-width: 1400px) {
                            width: calc(645px - 8.333%);
                        }
                    }
                    &right {
                        width: 100%;

                        @media (min-width: 768px) {
                            width: 65%;
                        }
                        @media (min-width: 992px) {
                            width: 58.333%;
                        }
                    }
                    &title {
                        // white-space: nowrap;
                        z-index: 1;

                        @media (min-width: 768px) {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}