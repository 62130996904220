body {
    font-family: $open-sans;
}

#{$app}- {
    &title {
        font-size: var(--fs-title);
        line-height: calc(var(--fs-title) + 0.8rem);
        color: $color-secondary;
        font-weight: 700;
        margin-bottom: 20px;

        &-- {
            &primary {
                color: $color-primary;
            }
            &md {
                font-size: calc(var(--fs-title) - 1.20rem);
                line-height: calc(var(--fs-title) - 1.20rem + 0.8rem);
            }

            &light {
                font-weight: 300;
            }
        }

        &__ {
            &font {
                &- {
                    &secondary {
                        font-family: $nunito;
                    }
                }
            }
            &underline {
                position: relative;
                padding-bottom: 1.5rem;

                &::before {
                    content: "";
                    position: absolute;
                    width: 20%;
                    height: 2px;
                    background-color: $color-tertiary;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
    }

    &subtitle {
        font-size: var(--fs-subtitle);
        line-height: calc(var(--fs-subtitle) + 0.8rem);
        color: $color-primary;
        font-weight: 400;
        display: inline-block;
        padding-right: 15px;
        padding-bottom: 15px;
        position: relative;
        margin-bottom: 10px;

        &-- {
            &md {
                font-size: calc(var(--fs-subtitle) - 1.20rem);
                line-height: calc(var(--fs-subtitle) - 1.20rem + 0.8rem);
            }
        }

        &::before {
            content: "";
            width: 100%;
            height: 2px;
            background: $color-tertiary;
            position: absolute;
            bottom: 0;
            left: 0;
            
        }
    }

    &para {
        font-size: var(--fs-para);
        font-family: $nunito;
        line-height: calc(var(--fs-para) + 0.8rem);
        color: $color-para;
        font-weight: 400;

        &-- {
            &white {
                color: #fff;
            }
        }
    }

    &text {
        font-size: var(--fs-text);
        font-family: $nunito;
        line-height: calc(var(--fs-text) + 0.8rem);
        color: $color-para;
        font-weight: 300;
    }
}