
html {
    font-size: 10.5px;

    @media (min-width: 768px) {
        font-size: 11px;
    }
    // @media (min-width: 992px) {
    //     font-size: 12px;
    // }
    @media (min-width: 1400px) {
        font-size: 13px;
    }
    @media (min-width: 1600px) {
        font-size: 14.5px;
    }
    // @media (min-width: 2000px) {
    //     font-size: 15px;
    // }
    // @media (min-width: 2000px) {
    //     font-size: 18px;
    // }
}
@media (min-width: 1600px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
        max-width: 1500px;
    }
}

body {
    background: #ffffff;
}
button:focus {
    outline: none !important;
}
a {
    display: inline-block;
    text-decoration: none !important;
}
p {
    margin-bottom: 0;
}

.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0 !important;
}
span {
    display: inline-block;
}
.Toastify__toast--success {
    background: $color-primary;
}