#{$app}- {
    &profile {
        &__ {
            &edit {
                &- {
                    &title {
                        font-size: 1.6rem;
                        font-weight: 600;
                        color: #404040;
                        padding-bottom: 1.5rem;
                        margin-bottom: 2rem;
                        border-bottom: 1.5px solid #8A959E28;
                    }
                }
            }
        }
    }
}