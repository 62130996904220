.programe-link {
    position: relative;
}

.programe-link:hover .event-hover {
    display: block;
}

.event-hover {
    width: 150px;
    position: absolute;
    display: none;
    // bottom: -110px;
    top: 40px;
    background-color: #fff;
    padding: 12px 18px;
    border-radius: 10px ;
    ul {
        list-style: none;
        padding:0;
        li{
            padding: 5px 16px;
            cursor: pointer;
        }
    }
}