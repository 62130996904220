#{$app}- {
    &process {
        width: 100%;
        // padding: 0 10px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @media (min-width: 768px) {
            width: 50%;
            margin-bottom: 0px;
        }

        &:nth-child(2) {
            @media (min-width: 768px) {
                padding-top: 100px;
            }
        }
        &:last-child {
            @media (min-width: 768px) {
                z-index: -2;
            }
        }

        &__ {
            &card {
                padding: 30px 20px 30px 0;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background: #fff;
                    border-radius: 15px;
                    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);
                    z-index: -1;

                    @media (min-width: 768px) {
                        width: 79%;
                    }
                }

                &- {
                    &line {
                        right: 0px;
                        top: 0;
                        z-index: 1;

                        @media (min-width: 1400px) {
                            right: 0px;
                        }

                        & img {
                            width: 35%;
                            margin-left: 80px;
                        }

                        &-- {
                            &bottom {
                                top: unset;
                                bottom: 0;
                                right: 0px;
                                z-index: -1;

                                @media (min-width: 1400px) {
                                    right: 0px;
                                }
                            }
                        }
                    }
                    &image {
                        width: 150px;
                        & img {
                            width: 100%;
                            // max-height: 220px;
                        }
                    }
                    &content {
                        width: calc(100% - 150px);
                    }
                    &title {
                        font-size: calc(26px - 8px);
                        line-height: 26px;
                        font-weight: 600;
                        color: $color-primary;
                        margin-bottom: 5px;
                    }
                    &para {
                        font-size: calc(var(--fs-text-sm));

                        @media (min-width: 992px) {
                            font-size: calc(var(--fs-text-sm) - 0.15rem);
                        }
                    }
                }
            }
        }
    }
}