@import "./mentor-package",
        "./mentor-testimonial";


#{$app}- {
    &mentor {
        &__ {
            &testimonial {
                &-{
                    &title{
                        @media only screen and (max-width: 600px) {
                            font-size: 24px;
                        }
                    }
                    &subtitle{
                        color: #007A87;
                    }
                    &cards{
                        font-family: "Nunito", sans-serif;
                        background: #fff;
                        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);
                        border: 1px solid #f0f0f0;
                        cursor: pointer;

                        &-{
                            &profile{
                                background: lightgrey;
                                overflow: hidden;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                                
                                @media only screen and (max-width: 1920px){
                                    width: 150px;
                                    height: 115px;
                                }
                                @media only screen and (min-width: 1920px){
                                    width: 200px;
                                    height: 170px;
                                }
                            }

                            

                            &name{
                                font-weight: 700;
                                color: #007A87;
                            }

                            &content{
                                width: calc(100% - 200px);
                                padding: 10px;
                                margin-left: 6px;
                            }

                            &para{
                                margin-top: 5px;
                                line-height: 1.6em;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2; /* number of lines to show */
                                        line-clamp: 2; 
                                -webkit-box-orient: vertical;
                            }
                        }
                    }

                    &highlighted{
                        margin-top: 6rem;
                        background-color: #ffffff;
                        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);
                        border: 1px solid #f0f0f0;
                        border-radius: 20px;

                        &-{
                            &profile{
                                width: 100%;
                                height: 270px;
                                background: lightgrey;
                                overflow: hidden;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                            }

                            &content{

                            }

                            &name{
                                font-weight: 700;
                                color: #007b87;
                            }
                        }
                    }
                    
                }
            }
        }
    }
}


.swiper-container{

    @media only screen and (max-width: 1920px){
        height: 380px;
    }
    @media only screen and (min-width: 1920px){
        height: 600px;
    }
    
}