#{$app}- {
    &footer {
        padding-top: var(--section-spacing);

        &-- {
            &green {
                background-color: $color-background-green;
            }
        }

        &__ {
            &col {
                &- {
                    &right {
                        @media (min-width: 992px) {
                            padding-top: 40px;
                        }
                    }
                }
            }
            &cta {
                background-color: $color-primary;
                border-radius: 10px;

                &-- {
                    &white {
                        color: #ffffff;
                    }
                }

                &- {
                    &wrap {
                        margin-bottom: -150px;
                    }
                    &content {
                        width: 100%;
                        padding: 20px;
                        color: #fff;

                        @media (min-width: 768px) {
                            padding: 40px;
                        }

                        @media (min-width: 992px) {
                            padding: 50px;
                        }
                        @media (min-width: 1200px) {
                            width: calc(100% - 400px);
                            padding: 70px 0 70px 70px;
                        }
                        @media (min-width: 1300px) {
                            width: calc(100% - 400px);
                            padding: 70px 0 70px 70px;
                        }
                    }
                    &image {
                        width: 100%;

                        @media (min-width: 768px) {
                            width: 40%;
                        }
                        @media (min-width: 1200px) {
                            width: 400px;
                        }
                        @media (min-width: 1300px) {
                            width: 400px;
                        }
                    }
                    &title {
                        font-weight: 400;
                    }
                    &actions {
                        font-size: calc(var(--fs-text-sm) + 2px);
                        line-height: calc(var(--fs-text-sm) + 2px + 0.8rem);
                    }
                    &action {
                        width: 100%;
                        color: #ffffff;

                        @media (min-width: 992px) {
                            width: auto;
                        }

                        &:hover {
                            color: #ffffff;
                        }
                    }
                }
            }

            &main {
                background: #EAF4F5;
            }

            &content {
                padding: 180px 0 30px 0;

                @media (min-width: 768px) {
                    padding: 190px 0 40px 0;
                }
            }

            &menu {
                &- {
                    &item {
                        margin: 0 10px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    &link {
                        color: #030303;
                        font-size: calc(var(--fs-text-sm));
                        line-height: calc(var(--fs-text-sm) + 0.8rem);
                        font-weight: 600;
                        margin-bottom: 10px;
                        transition: all 0.2s ease;

                        // @media (min-width: 992px) {
                        //     font-size: calc(var(--fs-text-sm) - 2px);
                        //     line-height: calc(var(--fs-text-sm) - 2px + 0.8rem);
                        // }
                        
                        &:hover {
                            color: #1f1f1f;
                            transform: translateX(3px);
                        }
                    }
                }
            }
            &submenu {
                &- {
                    &item {
                        &:last-child a {
                            margin-bottom: 0;
                        }
                    }
                    &link {
                        font-weight: 400;
                    }
                }
            }

            &credentials {
                font-size: calc(var(--fs-text-sm) - 2px);
                border-top: 1px solid rgba($color: #707070, $alpha: 0.23);
                padding: 20px 0;

                &- {
                    &link {
                        cursor: pointer;
                    }
                    &links, &text {
                        color: #a1a1a1;

                        &:hover {
                            color: #a1a1a1;
                        }
                    }
                }
            }

            &social {
                &- {
                    &link {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba($color: #5A5A5A, $alpha: 0.30);
                        border-radius: 100px;
                        margin-right: 10px;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}