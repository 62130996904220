#{$app}- {
    &hero {
        padding: 60px 0 var(--section-spacing) ;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        &__ {

            &slider {
                & .owl-stage-outer {
                    @media (min-width: 992px) {
                        padding-bottom: 100px;
                    }
                }
                & .owl-dots {
                    @media (min-width: 992px) {
                        position: absolute;
                        left: 70px;
                        bottom: 50px;
                    }
                }
            }
            
            &slide {
                &- {
                    &title {
                        &-- {
                            &gray {
                                color: $color-secondary;
                            }
                        }
                    }
                    &text {
                        &-- {
                            &green {
                                color: $color-primary;
                            }
                        }
                    }
                    &list {
                        font-size: var(--fs-title-medium);
                        color: $color-primary;
                        font-weight: 600;
                        margin-left: 10px;
                    }
                    &left {
                        width: 100%;
                        padding: 0 25px 0 25px;

                        @media (min-width: 992px) {
                            // width: 41.66667%;
                            width: calc(475px - 8.333%);
                            padding: 0 15px 0 15px;
                        }
                        @media (min-width: 1100px) {
                            // width: 41.66667%;
                            width: calc(570px - 8.333%);
                        }

                        @media (min-width: 1400px) {
                            width: calc(660px - 8.333%);
                        }
                        @media (min-width: 1600px) {
                            width: calc(750px - 8.333%);
                        }
                        @media (min-width: 2500px) {
                        }
                    }
                    &content {
                        @media (min-width: 1100px) {
                            padding: 10rem 0;
                        }

                        @media (min-width: 1320px) {
                            // padding-left: 30px;
                        }
                    }
                    &line {
                        width: 100%;
                        display: none;
                        z-index: -1;

                        @media (min-width: 768px) {
                            display: inline-block;
                        }

                        &1 {
                            // top: -3px;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    &home {
        &__ {
            &event {
                // width: 100%;

                @media (min-width: 768px) {
                    width: 350px;
                }
                @media (min-width: 1320px) {
                    width: 400px;
                }
                @media (min-width: 1600px) {
                    width: 500px;
                }

                &s {
                    &-text {
                        &-- {
                            &noupcoming {
                                font-size: 1.4rem;
                                font-weight: 700;
                                color: #9f9f9f;
                            }
                        }
                    }
                }
            }

            &testimonial {
                &- {
                    &slider .owl-stage-outer {
                        overflow: visible !important;
                    }
                }
            }
        }
    }
}