.sc {
    color: #525252;
}

.image-text {
    color:  #007A87;
    font-size: 36px;
    margin-bottom: 0;
    font-weight: 600;
}

.about-container {
    margin-top: 80px;
    p{
        color: #525252;
        line-height: 1.75rem;
    }
  

    .about-img {
        width: 394px;
        height: 325px;
        margin-left: 25px;
        border-radius: 22px;
        float: right;
    }
    
}

.founder-section {
    // .col-md-7 {
        background-color: rgba(0, 122, 135, 0.10);
    // }
    h6, h3{
        color: #007A87;
    }
    
}