#{$app}- {
    &job {
        padding: 2rem;
        background: #fff;
        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);
        margin-bottom: 2rem;
        border-radius: 8px;
        border: 1px solid $color-border-color;

        &:last-child {
            margin-bottom: 0px;
        }

        &__ {
            &logo {
                height: 120px;
                padding-bottom: 1.5rem;
                border-bottom: 2px solid $color-tertiary;
                display: flex;
                align-items: center;
                justify-content: center;

                & img {
                    height: 100%;
                }
            }
            &company {
                &- {
                    &name {
                        font-size: var(--fs-text-sm);
                        color: #313131;
                        margin: 15px 0 20px;
                    }
                }
            }
            &openings {
                font-size: 1.1rem;;
                font-weight: 500;
                margin-bottom: 30px;
            }
        }
    }
}