#{$app}- {
    &whatsapp {
        &__ {
            &cta {
                &- {
                    &section {
                        position: relative;
                        background-size: cover;
                        background-position: center;
                        border-bottom-right-radius: 150px;
                        overflow: hidden;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-color: rgba($color: #00A638, $alpha: 0.05);
                        }
                    }
                    &left {
                        & img {
                            height: 200px;

                            @media (min-width: 992px) {
                                height: 300px;
                            }
                        }
                    }
                    &content {
                        margin-bottom: 30px;
                    }
                    &title {
                        color: #313131;
                        font-size: var(--fs-title);
                        line-height: calc(var(--fs-title) + 0.8rem);
                        font-weight: 700;
                        font-family: $nunito;
                    }
                    &subtitle {
                        color: #313131;
                        font-size: var(--fs-subtitle);
                        line-height: calc(var(--fs-subtitle) + 0.8rem);
                        font-weight: 700;
        
                        &-- {
                            &whatsapp {
                                color: #00AD39;
                            }
                        }
                    }
                    &button {
                        &-- {
                            &whatsapp {
                                font-size: var(--fs-text-sm);
                                font-family: $nunito;
                                background: #034C3F;
                                color: #ffffff;
                                padding:15px 20px;
                                border-radius: 8px;
                                transition: all 0.2s ease;

                                &:hover {
                                    color: #ffffff;
                                    background-color: #067460;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}