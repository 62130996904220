#{$app}- {
    &rating {
        height: 100%;
        padding: 30px;
        background: #fff;
        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.16);

        &__ {
            &value {
                width: 130px;
                height: 130px;
                margin: 0 auto;

                &- {
                    &wrap {
                        border-bottom: 2px solid $color-tertiary;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                }

            }
            &para {
                font-size: var(--fs-text-sm);
                line-height: calc(var(--fs-text-sm) + 0.8rem);
                text-align: center;
            }
        }
    }
}