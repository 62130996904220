.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.modal-show {
  display: block;
}

.modal-hide {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#root .App .Social_btn .google_btn svg{
  height: 38px!important;
}
.App-link {
  color: #61dafb;
}
.down_pop .Social_btn button:nth-child(1) svg{
  height: 20px;
  margin-bottom: 5px;
}
.down_pop .Social_btn button:nth-child(2) svg{
  vertical-align: sub;
    margin-bottom: 3px;
}
.Social_btn button{
  width: 49%;
  line-height: 41px;
}
.login_sign_popup{
  display: inline-block;
  background: #FFFFFF;
  border-radius: 13px;
  /* position: fixed; */
  top: calc(50% - 155px);
  left: calc(50% - 250px);
  min-width: 300px;
}

.login_sign_popup .up_pop{
  background: radial-gradient(122.81% 731.25% at 86.24% 114.56%, rgba(19, 184, 154, 0.3) 0%, rgba(88, 135, 255, 0) 100%), linear-gradient(180deg, #014961 0%, #082550 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top-right-radius:13px ;
  border-top-left-radius: 13px;
}
.login_sign_popup .up_pop p{
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
}
.login_sign_popup .up_pop span{
  padding: 0 7px;
  background-color: #fff;
  font-size: 20px;
  border-radius: 3px;
  cursor: pointer;
}
.login_sign_popup .down_pop{
  padding: 20px;
}
.login_sign_popup .down_pop .account_p{
  background: radial-gradient(122.81% 731.25% at 86.24% 114.56%, rgba(19, 184, 154, 0.3) 0%, rgba(88, 135, 255, 0) 100%), linear-gradient(180deg, #014961 0%, #082550 100%);
  border-radius: 10px;
}
#root .Social_btn button:nth-child(1){
  background-color: #F81D00!important;
  color: #fff!important;
  display: flex!important;
  justify-content: center!important;
  border-radius: 6px!important;
  outline: none!important;
  border: none!important;
  cursor: pointer;
  opacity: 1!important;
}
#root .Social_btn button:nth-child(1) div{
  padding: 0px!important;
  background-color: unset!important;
}
#root .Social_btn button:nth-child(1) div svg g{
  color:#fff!important;
}
#root .Social_btn button:nth-child(1) span{
  font-size: 19px!important;
  text-transform: capitalize;
  padding: 0px!important;
}
/* @media only screen and (max-width: 767px){
  .login-form-content .Social_btn button{
    padding:7.5px 50px;
  }
}
@media only screen and (max-width: 545px){
  .login-form-content .Social_btn button{
    padding:7.5px 20px;
    font-size: 14px;
  }
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
