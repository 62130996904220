#{$app}- {
    &entry {
        &__ {
            &card {
                &- {
                    &wrap {
                        border: 2px solid #eaeaea !important;
                        border-radius: 20px !important;

                        &.active {
                            border: 2px solid $color-primary !important;
                        }
                    }
                }
            }
            &error {
                &- {
                    &text {
                        color: red;
                    }
                }
            }
        }
    }
}

input:focus, select:focus, textarea:focus {
    border: 1px solid $color-primary !important;
    box-shadow: none !important;
}
.signup-wrap.login-page .login-bg {
    width: 40%;
    position: fixed;
    left: 0;
}

.signup-wrap.login-page .login-form {
    width: 60%;
    padding-top: 20px;
    margin-left: auto;
}

@media (min-width: 1550px) {
    .signup-wrap.login-page .login-bg {
        width: 30%;
    }
    .signup-wrap.login-page .login-form {
        width: 70%;
    }
}

.signup-wrap.login-page .login-form .logo-img-bottom {
    margin-bottom: 70px;
}

@media (max-width: 991px) {
    .signup-wrap.login-page .login-form {
        width: 100%;
    }
}

.signup-wrap .btn-wrap {
    text-align: right;
}

.signup-wrap .btn-wrap .btn.btn-gradient-primary {
    padding: 10px 40px;
}

.signup-wrap .btn-wrap .btn-gray-color {
    color: #727272;
    margin-right: 16px;
}

.signup-wrap .login-form-content .sec-title .title {
    color: #0c4b6c;
}

.signup-wrap .login-form-content .sec-content {
    margin-bottom: 30px;
}

.signup-wrap .login-form-content .sec-content p {
    color: #727272;
}

.signup-wrap .signup-wrap-content .card .card-image img {
    width: 100%;
}

.signup-wrap .signup-wrap-content .card.active .card-img-top {
    // border: 2px solid #007a87;
    border-radius: 20px;
    display: inline-block;
    padding: 20px;
}

.signup-wrap .signup-wrap-content .card .card-img-top {
    height: 100%;
    // border: 2px solid #ccc;
    border-radius: 20px;
    display: inline-block;
    padding: 20px;
    cursor: pointer;
}

.signup-wrap .signup-wrap-content .card.active .card-text-title {
    color: #007a87;
}

.signup-wrap .signup-wrap-content .card-body {
    text-align: center;
    padding: 0 1.25rem 1.25rem;
}

.signup-wrap .basic-detail {
    margin-bottom: 100px;
    width: 90%;
}

@media (max-width: 576px) {
    .signup-wrap .basic-detail {
        width: 100%;
        margin-bottom: 60px;
    }
}

.login-page,
.survey-page {
    display: flex;
}

@media (max-width: 991px) {
    .login-page,
    .survey-page {
        display: block;
    }
}

.login-page .nav-pills,
.survey-page .nav-pills {
    border-bottom: 1px solid rgba(11, 62, 172, 0.07);
    margin-bottom: 40px;
}

.login-page .form-content .login-form-content,
.survey-page .form-content .login-form-content {
    margin-bottom: 100px;
}

.login-page .form-content .login-form-content .logo-image-wrap,
.survey-page .form-content .login-form-content .logo-image-wrap {
    text-align: center;
}

@media (max-width: 768px) {
    .login-page .form-content .login-form-content,
    .survey-page .form-content .login-form-content {
        margin-bottom: 40px;
    }
}

.login-page .form-content .sec-content p,
.survey-page .form-content .sec-content p {
    color: #585858;
    /* font-family: "santralregular" !important; */
    font-size: 16px;
    letter-spacing: 0;
}

.login-page .form-content .forgot-link a,
.survey-page .form-content .forgot-link a {
    color: #0b3eac;
    /* font-family: "santralregular" !important; */
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    text-align: right;
}

.login-page .form-content .form-group label,
.survey-page .form-content .form-group label {
    color: #222222;
    // font-family: "santralregular" !important;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}

.login-page .login-bg,
.login-page .forgot-bg,
.survey-page .login-bg,
.survey-page .forgot-bg {
    text-align: center;
    // background: url(/assets/img/login-bg.png) no-repeat;
    background-position: center;
    background-size: cover;
    width: 30%;
    display: flex;
    justify-content: center;
}

.login-page .login-bg img,
.login-page .forgot-bg img,
.survey-page .login-bg img,
.survey-page .forgot-bg img {
    width: 100%;
}

@media (max-width: 991px) {
    .login-page .login-bg,
    .login-page .forgot-bg,
    .survey-page .login-bg,
    .survey-page .forgot-bg {
        display: none;
    }
}

.login-page .login-bg .login-title,
.login-page .forgot-bg .login-title,
.survey-page .login-bg .login-title,
.survey-page .forgot-bg .login-title {
    margin-bottom: 80px;
}

.login-page .login-bg .login-title h4,
.login-page .forgot-bg .login-title h4,
.survey-page .login-bg .login-title h4,
.survey-page .forgot-bg .login-title h4 {
    color: #0b3eac;
    /* font-family: "santralregular" !important; */
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
}

.login-page .login-bg .login-content p,
.login-page .forgot-bg .login-content p,
.survey-page .login-bg .login-content p,
.survey-page .forgot-bg .login-content p {
    color: #3e3e3e;
    /* font-family: "santralregular" !important; */
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
}

.login-page .login-bg .slick-dots,
.login-page .forgot-bg .slick-dots,
.survey-page .login-bg .slick-dots,
.survey-page .forgot-bg .slick-dots {
    position: absolute;
    bottom: -150px;
    left: 0;
    right: 0;
}

@media (max-width: 1150px) {
    .login-page .login-bg .slick-dots,
    .login-page .forgot-bg .slick-dots,
    .survey-page .login-bg .slick-dots,
    .survey-page .forgot-bg .slick-dots {
        bottom: -140px;
    }
}

.login-page .login-form,
.login-page .forgot-form,
.survey-page .login-form,
.survey-page .forgot-form {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 60px 60px;
    border-radius: 0px;
    background-color: #ffffff;
    // box-shadow: 0 2px 28px 0 rgba(117, 161, 222, 0.26);
}
.signup-wrap.login-page .login-form .form-group {
    margin-bottom: 20px;
}
.signup-wrap.login-page .login-form .form-group label {
    font-size: var(--fs-text-sm);
    margin-bottom: 12px;
}

.signup-wrap.login-page .login-form .form-group.active label,
.signup-wrap.login-page .login-form .form-group.active .form-control::placeholder {
    color: #0c4b6c;
}

.signup-wrap.login-page .login-form .form-group.active .form-control {
    color: #0c4b6c;
    border: 1px solid #0c4b6c;
}

.signup-wrap.login-page .login-form sup {
    color: #ff0000;
    font-size: 15px;
    top: -0.1rem;
}

@media (max-width: 991px) {
    .login-page .login-form,
    .login-page .forgot-form,
    .survey-page .login-form,
    .survey-page .forgot-form {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .login-page .login-form,
    .login-page .forgot-form,
    .survey-page .login-form,
    .survey-page .forgot-form {
        padding: 60px 20px;
    }
}

.login-page .login-form .login-content-wrap,
.login-page .forgot-form .login-content-wrap,
.survey-page .login-form .login-content-wrap,
.survey-page .forgot-form .login-content-wrap {
    width: 100%;
}

.login-page .login-form .sec-content p,
.login-page .forgot-form .sec-content p,
.survey-page .login-form .sec-content p,
.survey-page .forgot-form .sec-content p {
    color: #9b9b9b;
    /* font-family: "santralregular" !important; */
}

.login-page .login-form .survey-list .list-title .title,
.login-page .forgot-form .survey-list .list-title .title,
.survey-page .login-form .survey-list .list-title .title,
.survey-page .forgot-form .survey-list .list-title .title {
    font-size: 16px;
    margin-bottom: 30px;
}

.login-page .login-form .survey-list .list-field-wrap .list-field,
.login-page .forgot-form .survey-list .list-field-wrap .list-field,
.survey-page .login-form .survey-list .list-field-wrap .list-field,
.survey-page .forgot-form .survey-list .list-field-wrap .list-field {
    background-color: #f6f6f6;
    padding: 16px 30px;
    margin-bottom: 2rem;
    border-radius: 10px;
}

.login-page .login-form .progress,
.login-page .forgot-form .progress,
.survey-page .login-form .progress,
.survey-page .forgot-form .progress {
    height: 10px;
}

.login-page .form-content .forgot-link,
.survey-page .form-content .forgot-link {
    font-size: 16px;
    font-weight: 600;
    color: #007a87 !important;
}

.login-page .login-tab-content .nav-pills .nav-link.active,
.login-page .login-tab-content .nav-pills .show>.nav-link,
.login-page .login-tab-content .nav-pills .nav-link,
.login-page .forgot-tab-content .nav-pills .nav-link.active,
.login-page .forgot-tab-content .nav-pills .show>.nav-link,
.login-page .forgot-tab-content .nav-pills .nav-link,
.survey-page .login-tab-content .nav-pills .nav-link.active,
.survey-page .login-tab-content .nav-pills .show>.nav-link,
.survey-page .login-tab-content .nav-pills .nav-link,
.survey-page .forgot-tab-content .nav-pills .nav-link.active,
.survey-page .forgot-tab-content .nav-pills .show>.nav-link,
.survey-page .forgot-tab-content .nav-pills .nav-link {
    border: none;
    background-color: transparent;
    padding: 0px;
    color: #007a87;
}

.login-page .login-tab-content .nav-pills .nav-link,
.login-page .forgot-tab-content .nav-pills .nav-link,
.survey-page .login-tab-content .nav-pills .nav-link,
.survey-page .forgot-tab-content .nav-pills .nav-link {
    opacity: 0.5;
    color: #007a87;
    font-size: 16px;
    margin-right: 24px;
}

.login-page .login-tab-content .nav-pills .nav-link.active,
.login-page .forgot-tab-content .nav-pills .nav-link.active,
.survey-page .login-tab-content .nav-pills .nav-link.active,
.survey-page .forgot-tab-content .nav-pills .nav-link.active {
    color: #007a87;
    opacity: 1;
    border-bottom: 2px solid #007a87;
    border-radius: 0px;
    padding-bottom: 1rem;
}

.login-page .login-tab-content .form-content .logo-image,
.login-page .forgot-tab-content .form-content .logo-image,
.survey-page .login-tab-content .form-content .logo-image,
.survey-page .forgot-tab-content .form-content .logo-image {
    text-align: center;
    margin-bottom: 40px;
}

.login-page .login-content-wrap,
.login-page .forgot-content-wrap,
.survey-page .login-content-wrap,
.survey-page .forgot-content-wrap {
    width: 100%;
}
.login-form-content .btn-gradient-primary {
    background: radial-gradient( 122.81% 731.25% at 86.24% 114.56%, rgba(19, 184, 154, 0.3) 0%, rgba(88, 135, 255, 0) 100%), linear-gradient(
180deg, #014961 0%, #082550 100%);
    color: #fff;
    border-radius: 6px;
}
body .form-control {
    font-size: 1.175rem;
    padding: 12px 20px;
    height: auto;
}
.signup-wrap-content .card {
    height: 100%;
    border: 0;
}


// $assetPath: "/assets/images";

.select-group select {
    // -webkit-appearance: none;
    // appearance: none;
    // background: url(#{$assetPath}/select-arrow-icon.png) 96% center no-repeat #fff;
    background-color: white;
}