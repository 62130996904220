#{$app}- {
    &mentor {
        &__ {
            &banner {
                &- {
                    &img {
                        height: 500px;
                        overflow: hidden;
                        position: relative;
                        animation: animMentor 100s linear infinite;
                        background-repeat-y: repeat!important;
                        background-repeat-x: no-repeat!important;
                        background-position: center;
                        background-size: cover;

                        &A {
                            // position: absolute;
                            // left: 0;
                            // top: 500px;
                            // background: url('../images/mentors/our-mentor-banner.jpg');
                        }
                    }
                    &section {
                        min-height: 700px;
                    }
                    &inwrap {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                    }
                    &container {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &content {
                        padding: 60px 30px;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
                            background: #000000 0% 0% no-repeat padding-box;
                            opacity: 0.41;
                        }
                    }
                }
            }
            &testimonial {
                &- {
                    &left {
                        @media (min-width: 960px) and (max-width: 1100px) {
                            width: calc(480px - 8.333%);
                        }
                    }
                }
            }
        }
    }
}

@keyframes animMentor {
    to {
        // background-position: 0 -4417px;
        background-position: 0 -10000px;
    }
}