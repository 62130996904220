#{$app}- {
    &terms {
        &__ {
            &section {
                &- {
                    &title {
                        margin-bottom: 2rem;
                    }
                }
            }
            &para, &list {
                font-size: var(--fs-text-sm);
                margin-bottom: 1.5rem;
            }
            &divider {
                height: 50px;
                border-top: 2px solid;
                border-bottom: 2px solid;
                margin: 30px 0;
            }
            &list {
                list-style: lower-alpha;
                padding-left: 30px;

                & li {
                    padding-left: 10px;
                    margin-bottom: 1.3rem;
                }

                &- {
                    &roman {
                        list-style: lower-roman;
                    }
                    &pointer {
                        list-style: disc;
                    }
                }
            }
        }
    }
}