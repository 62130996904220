@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

#{$app}- {
    &profile {
        &__ {
            &cover-background {
                width: 100%;
                height: 250px;
                background: #E6F4FC;
                top: 0;
                z-index: 0;
            }
            &details {
                z-index: 1;
                position: relative;
                margin-top: -11rem !important;
            }
            &image {
                width: 22rem;
                height: 22rem;
                margin: 0  auto;
                background-color: rgba($color: $color-primary, $alpha: 0.50);
                border-radius: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                overflow: hidden;
                margin-bottom: 2rem;
            }
            &row {
                margin-bottom: 2rem;
            }
            &col {
                margin-bottom: 1rem;
            }
            &package {
                &- {
                    &icon {
                        width: 4rem;
                        height: 4rem;
                        border: 1px solid green;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 1rem;
                    }
                }
            }
            &right {
                @media (min-width: 992px) {
                    width: calc(100% - 22rem);
                    padding-left: 4rem;
                }

                &- {
                    &inner {
                        padding: 2rem 3rem;
                        background: #ffffff;
                        border-radius: 8px;

                        @media (min-width: 768px) {
                            padding: 4rem 6rem;
                        }
                    }
                }
            }
            &name {
                color: $color-primary;
                font-size: var(--fs-title-medium);
                font-weight: bold;

                &::before {
                    content: "";
                    position: absolute;
                    width: 130px;
                    height: 1px;
                    background: $color-tertiary;
                    bottom: 0;
                }

                @media (min-width: 768px) {
                    max-width: 70%;
                }
            }
            &number {
                font-size: 1.2rem;
                font-family: $nunito;
                font-weight: 600;
                color: #212121;
                margin-top: 1rem;

                &:hover {
                    color: #212121;
                }

                @media (min-width: 768px) {
                    margin-top: 0;
                    margin-left: 3rem;
                }
            }
            &label {
                color: #707070;
                font-size: var(--fs-text-sm);
                font-weight: 400;
                margin-bottom: 0.2rem;
            }
            &data {
                font-size: var(--fs-text-sm);
                color: #afafaf;
            }
            &btn {
                &- {
                    &edit {
                        padding: 0.5rem 2rem;
                        font-size: 1rem;
                        font-weight: 500;
                        font-family: $nunito;
                        border: 1px solid $color-tertiary;
                        border-radius: 100px;
                        transition: all 0.2s ease;

                        &:hover {
                            color: #fff;
                            background: $color-tertiary;
                        }
                    }
                }
            }
            &item, &section-title {
                font-size: 1.3rem;
                color: #212121;
                font-family: $nunito;
                font-weight: 600;

                &:hover {
                    color: #212121;
                }

                &- {
                    &link {
                        color: $color-primary !important;
                        text-decoration: underline !important;
                    }
                }
            }

            &section {
                &- {
                    &title {
                        padding: 1rem;
                        background-color: #F8F8F8;
                        border-top: 1px solid #e7e7e7;
                        border-bottom: 1px solid #e7e7e7;
                    }
                }
            }

            &agenda {
                &- {
                        @media (max-width: 767px) {
                            &wrap {
                                overflow-x: scroll;
                            }
                            &table {
                                width: 700px;
                                & td {
                                    font-size: 1.2rem;
                                }
                            }
                        }
                    &row td {

                    }
                }
            }
        }
    }
}

.rbc-event-label {
    display: none;
}
.rbc-agenda-table tr th:nth-child(2), .rbc-agenda-table tr td:nth-child(2) {
    display: none !important;
}
.rbc-calendar {
    font-family: 'Open Sans';
}
.rbc-month-header {
    background-color: $color-primary;
    color: #fff;
}
.rbc-header {
    font-size: 1rem;
    font-family: $nunito;
    font-weight: 400;
    padding: 1rem 0 !important;
}
.rbc-event, .rbc-day-slot .rbc-background-event {
    padding: 7px 5px;
    background-color: #B4E9EF;
    color: #007A87;
    text-align: center;
    font-weight: 600;
}
.rbc-today {
    background-color: #007A8717;
}
.rbc-toolbar button {
    padding: 0.675rem 1rem;
    font-size: 0.9rem;
}
.rbc-toolbar-label {
    font-size: 18px;
    font-weight: 700;
    font-family: $nunito;
    color: #1D2634;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    box-shadow: none !important;
    border-color: #ccc !important;
}
.rbc-toolbar button:hover {
    border-color: #ccc !important;
}
.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #7ed9e3;
    outline: 5px auto $color-primary;
}
.rbc-toolbar .rbc-toolbar-label {
    margin-bottom: 1rem;
}


.youtube-blog-wrap .youtube-blog-content .blog-content-wrap,
.youtube-blog-wrap .event-blog-content .blog-content-wrap,
.event-blog-wrap .youtube-blog-content .blog-content-wrap,
.event-blog-wrap .event-blog-content .blog-content-wrap {
    margin-bottom: 40px;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .image-wrap,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap,
.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap {
    position: relative;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap .play-icon,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .play-icon,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap .play-icon,
.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .play-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap .play-icon .play-icon-link i,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .play-icon .play-icon-link i,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap .play-icon .play-icon-link i,
.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .play-icon .play-icon-link i {
    color: #f02a2a;
    font-size: 60px;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap img,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .image-wrap img,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .image-wrap img,
.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap img {
    width: 100%;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap img {
    height: 250px;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content,
.event-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content {
    background-color: transparent;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content .sec-title,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content .sec-title,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content .sec-title,
.event-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content .sec-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #2d2d2d;
    /* font-family: "santralbold"; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content .sec-content,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content .sec-content,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .blog-text-content .sec-content,
.event-blog-wrap .event-blog-content .blog-content-wrap .blog-text-content .sec-content {
    color: #525252;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .meta-info,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info,
.event-blog-wrap .event-blog-content .blog-content-wrap .meta-info {
    color: #a9a9a9;
    padding-top: 16px;
    border-top: 1px solid #e7e7e7;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info .time,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .meta-info .time,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info .time,
.event-blog-wrap .event-blog-content .blog-content-wrap .meta-info .time {
    background: #f5fafa;
    padding: 7px 12px;
    color: #007a87;
    font-style: normal;
    font-weight: bold;
    border-radius: 5px;
    font-size: 14px;
}

.youtube-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info .days,
.youtube-blog-wrap .event-blog-content .blog-content-wrap .meta-info .days,
.event-blog-wrap .youtube-blog-content .blog-content-wrap .meta-info .days,
.event-blog-wrap .event-blog-content .blog-content-wrap .meta-info .days {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    text-align: right;
    text-transform: uppercase;
    color: #2d2d2d;
}

.blog-banner-wrap.youtube-blog-banner {
    background-position: center;
    min-height: 600px;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .event-time {
    padding-bottom: 16px;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .event-time a {
    color: #007a87;
    font-size: 15px;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .sec-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .sec-content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap {
    position: relative;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .tip-title {
    position: absolute;
    top: 20px;
    right: 20px;
}

.event-blog-wrap .event-blog-content .blog-content-wrap .image-wrap .tip-title span {
    /* font-family: "santralbold"; */
    color: #2d2d2d;
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 16px;
}



//card

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
}
.rounded {
    border-radius: 0.625rem !important;
}
.thumbnail-background {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4caf5061;
    height: 45px;
    width: 45px;
    border-radius: 8px;
}
.courses-content-list {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-left: 10px;
}
.courses-content-list .title {
    font-size: 12px;
    line-height: 18px;
}
.courses-content-list .description {
    font-size: 12px;
    line-height: 18px;
    color: #4caf50;
}
.course-list-header .progress1 p {
    font-size: 12px;
    color: #006064;
    margin-bottom: 8px;
    margin-top: 14px;
}
.course-list-header .title {
    font-size: 14px;
    color: #000;
}
.course-list-header {
    background-color: #f5fafa;
}
.progress {
    /* margin: 0 0; */
    padding: 0;
    width: 100%;
    overflow: hidden;
    background: rgba(0, 122, 135, 0.23);
    border-radius: 6px;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007a87;
    transition: width 0.6s ease;
}