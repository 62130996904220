#{$app}- {
    &input {
        &__ {
            &col {
                margin-bottom: 2rem;
            }
            &sub {
                color: #ff0000;
                font-size: 15px;
                top: -0.1rem;
            }
            &label {
                color: #222222;
                font-size: var(--fs-text-sm);
                margin-bottom: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
            }
            &upload-img {
                width: 70%;
                height: 70%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                border-radius: 100%;
                background: #00000066;
                z-index: 11;
                cursor: pointer;
            }
        }
    }
}