.programe-section-1 {
  // background-color: rgba(0, 122, 135, 0.1);
  text-align: center;
  .round-icon {
    height: 100px;
    width: 100px;
    background-color: #007a87;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fz-22 {
    font-size: 20px;
}



// $phoenix: #26B99F;
// $tribe: #4B81C2;
// $fly: #F04949;
// $whatsapp : #034C3F;

// .sh {
//   &- {
//     &subscription {
//       &__ {
//         &card {
//           height: 100%;
//           box-shadow: 0 6px 15px #d5d5d5;
//           border-radius: 10px;
//           background-color: #ffffff;
//           &- {
//             &inwrap {
//               height: 100%;
//               border-radius: 10px;
//               overflow: hidden;
//             }
//             &title {
//               font-size: 30px;
//               font-weight: 800;
//               padding: 15px;
//               color: #ffffff;
//               position: relative;

//               &::before {
//                 content: "";
//                 width: 10px;
//                 border-left: 10px solid transparent;
//                 border-right: 10px solid transparent;
//                 position: absolute;
//                 bottom: -9px;
//                 left: 0;
//                 right: 0;
//                 margin: 0 auto;
//               }

//               &-- {
//                 &phoenix {
//                   background-color: $phoenix;
//                   &::before {
//                     border-top: 10px solid $phoenix;
//                   }
//                 }
//                 &tribe {
//                   background-color: $tribe;
//                   &::before {
//                     border-top: 10px solid $tribe;
//                   }
//                 }
//                 &fly {
//                   background-color: $fly;
//                   &::before {
//                     border-top: 10px solid $fly;
//                   }
//                 }
//               }
//             }
//             &subtitle {
//               font-size: 18px;
//               font-weight: 800;
//               text-transform: uppercase;
//               margin-bottom: 30px;
//             }
//             &icon img {
//               width: 52px;
//             }
//             &content {
//               padding: 40px 30px;
//               display: flex;
//               flex-direction: column;
//               justify-content: space-between;

//               @media (min-width: 992px) {
//                 height: calc(100% - 81px);
//               }
//             }
//             &item {
//               display: flex;
//               // align-items: center;
//               margin-bottom: 15px;
//               font-size: 16px;

//               &s {
//                 height: 100%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: space-between;
//               }
//             }
//           }
//         }
//         &btn {
//           color: $whatsapp;
//           border: 2px solid $whatsapp;
//           border-radius: 5px;
//           padding: 10px 20px;
//           &:hover {
//             background-color: $whatsapp;
//             color: #ffffff;
//           }
//         }
//         &whatsapp-btn{
//           background: #fff;
//           border: 0;
//           box-shadow: 0 3px 4px 0px #3333333d;
//         }
//         &background {
//           width: 100%;
//           height: 100%;
//           position: absolute;
//           background-repeat: no-repeat;
//           background-size: cover;
//           top: 0;
//           left: 0;
//         }
//         &wrap {
//           position: relative;
//           width: 100%;
//           height: 100%;
//           background-repeat: no-repeat;
//           background-size: cover;
//           background-color: rgba(0, 122, 135, 0.1);;
//           top: 0;
//           left: 0;
//           padding: 50px 0;


//           @media (min-width: 992px) {
//             padding: 70px 0;
//           }

//           &-title {
//             font-size: 28px;
//             font-weight: 700;
//           }
//         }
//       }
//     }
//   }
// }