$phoenix: #26B99F;
$tribe: #4B81C2;
$fly: #F04949;
$whatsapp : #034C3F;

#{$app} {
  &- {
    &package {
      &__ {
        &card {
            height: 100%;
            box-shadow: 0 6px 15px #d5d5d5;
            border-radius: 5px 5px 0px 65px;
            background-color: #ffffff;
            border-top: 5px solid;
            overflow: hidden;

            & br {
              @media (max-width: 1100px) {
                display: none;
              }

            }

          &-- {
              &phoenix {
                  border-color: $phoenix;
              }
              &fly-high {
                border-color: $fly;
              }
              &she-tribe {
                  border-color: $tribe;
              }
          }

          &- {
            &inwrap {
              height: 100%;
            //   border-radius: 10px;
            //   overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            &top {
              padding: 2rem;
            // margin-bottom: 30px;
            }
            &title {
              font-size: 30px;
              font-weight: 300;
              padding-bottom: 8px;
              margin-bottom: 8px;
            //   color: #ffffff;
              position: relative;
              border-bottom: 3px solid $phoenix;

                &-- {
                    &phoenix {
                        border-color: $phoenix;
                    }
                    &fly-high {
                    border-color: $fly;
                    }
                    &she-tribe {
                        border-color: $tribe;
                    }
                }
                
            }
            &subtitle {
              font-weight: 800;
            //   text-transform: uppercase;
            //   margin-bottom: 30px;
            }
            &icon {
              width: 80px;
              @media (min-width: 992px) {
                width: 80px;
              }
              @media (min-width: 1300px) {
                width: 130px;
              }
            }
            &content {
                width: calc(100% - 80px);
                padding-left: 15px;

                @media (min-width: 992px) {
                  width: 100%;
                }
                @media (min-width: 1200px) {
                  width: calc(100% - 80px);
                }
                @media (min-width: 1300px) {
                  width: calc(100% - 130px);
                }
            }
            &content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              @media (min-width: 992px) {
                height: calc(100% - 81px);
              }
            }
            &item {
              display: flex;
              align-items: center;
              margin-bottom: 0.6rem;
              font-size: calc(var(--fs-text) - 0.2rem);
              line-height: calc(var(--fs-text) + 0.1rem);
              font-family: $nunito;

              &:last-child {
                margin-bottom: 0;
              }

              &s {
                height: 100%;
                display: flex;
                flex-direction: column;
                margin-top: 25px;
                // justify-content: space-between;
              }
            }
          }
        }
        &button {
            font-size: var(--fs-text);
            color: #ffffff;
            border: none;
            padding: 20px 20px;
            text-transform: uppercase;
            background: #007A87;
            transition: all 0.2s ease;

            & svg {
              transition: all 0.2s ease;
            }

            &:hover {
              background: #003D43;

              & svg {
                transform: translateX(10px);
              }
            }

            // &-- {
            //     &light {
            //         background: #007A87;
            //     }
            //     &dark {
            //         background: #003D43;
            //     }
            // }
        }
        // &btn {
        //   color: $whatsapp;
        //   border: 2px solid $whatsapp;
        //   border-radius: 5px;
        //   padding: 10px 20px;
        //   &:hover {
        //     background-color: $whatsapp;
        //     color: #ffffff;
        //   }
        // }
        &whatsapp-btn{
          background: #fff;
          border: 0;
          box-shadow: 0 3px 4px 0px #3333333d;
        }
        &background {
          width: 100%;
          height: 100%;
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          top: 0;
          left: 0;
        }
        &wrap {
          position: relative;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-color: rgba(0, 122, 135, 0.1);;
          top: 0;
          left: 0;
          padding: 50px 0;


          @media (min-width: 992px) {
            padding: 70px 0;
          }

          &-title {
            font-size: 28px;
            font-weight: 700;
          }
        }
      }
    }
  }
}