#{$app}- {
    &header {
        position: fixed;
        width: 100%;
        background: #ffffff;
        z-index: 1111;
        top: 0;
        box-shadow: 0px 3px 8px #0000000d;
        transition: all 0.2s ease;

        &.visible {
            top: 0px;
        }
        &.hidden {
            top: -70px;
        }

        &__ {
            &menu {
                &- {
                    &register {
                        font-size: var(--fs-text-sm);
                        line-height: calc(var(--fs-text-sm) + 1rem);
                        background: #F1CF25;
                        color: #313131 !important;
                        border: 1px solid #F1CF25;
                        // border-bottom-right-radius: 30px;
                        border-radius: 100px !important;
                        text-align: center;
                        transition: all 0.2s ease;
                        cursor: pointer;
                        position: relative;
                        padding: 0.6rem 3.6rem 0.6rem 3.6rem !important;

                        &:hover {
                            background: #f4b61c !important;
                        }
                    }
                    &link {
                        font-size: calc(var(--fs-text-sm) + 0.1rem);
                        font-family: $open-sans !important;
                        color: #595959 !important;
                        margin-right: 15px;
                        cursor: pointer;
                        position: relative;
                        transition: all 0.2s ease;
                        font-weight: 500;
                        padding: 1rem 1rem !important;

                        @media (min-width: 992px) {
                            padding: 0.5rem !important;
                            margin-right: 5px;
                        }
                        @media (min-width: 1200px) {
                            padding: 0.5rem 0.8rem !important;
                        }
                        @media (min-width: 1400px) {
                            padding: 0.5rem 1rem !important;
                            margin-right: 15px;
                        }

                        @media (min-width: 1024px) and (max-width: 1200px) {
                            font-size: 1rem;
                        }

                        // &::before {
                        //     content: "";
                        //     position: absolute;
                        //     width: 0%;
                        //     height: 1px;
                        //     background-color: #ddbd19;
                        //     top: unset;
                        //     left: unset;
                        //     bottom: 0;
                        //     margin: auto;
                        //     transition: all 0.8s ease;
                        // }

                        &:hover, &.active {
                            color: #007A87 !important;
                        }
                        // &:hover::before {
                        //     animation: menuHover 0.4s ease 0s;
                        // }

                        &.dropdown-item {
                            background: none !important;

                            &:hover {

                            }
                        }
                    }
                    &dropdown {

                        & .dropdown-toggle::after {
                            display: none;
                        }
                        @media (min-width: 768px) {                            
                            &:hover > .dropdown-menu {
                                display: block;
                                animation: menuDropdown 0.2s ease 0s forwards;
                            }
                        }
                    }
                    &profile {
                        display: flex;
                        align-items: center;
                        background-color: #f0f7f8;
                        color: #007a87;
                        border-radius: 100px;
                        padding: 12px 20px;
                        // padding-right: 20px !important;
                        padding-left: 0px !important;
                    }
                }
            }
            
        }
    }
}

@keyframes menuHover {
    0% {
        width: 0%;
        left: 0;
    }
    50% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0%;
        left: unset;
        right: 0;
    }
}

@keyframes menuDropdown {
    0% {
        // display: none;
        transform: translateY(-10px);
    }
    10% {
        display: block;
    }
    100% {
        display: block;
        transform: translateY(0px);
    }
}