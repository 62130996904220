#{$app}- {
    &slider {


        & .owl-stage-outer {
            padding-right: 20px;
            padding-bottom: 30px;
            // overflow: visible !important;
        }

        & .owl-dot {

            span {
                width: 20px !important;
                height: 4px !important;
                background: rgba($color: #F1CF25, $alpha: 0.20) !important;

                @media (min-width: 768px) {
                    width: 40px !important;
                }
            }
            &.active {
                & span {
                    background: rgba($color: #F1CF25, $alpha: 1) !important;
                }
            }
        }

        &__ {
            &event {
                position: relative;

                & .owl-stage-outer {
                    @media (min-width: 768px) {
                        padding-left: 15px;
                        padding-bottom: 15px;
                    }
                }

                @media (min-width: 768px) {
                    &::before {
                        content: "";
                        position: absolute;
                        width: 80px;
                        height: 100%;
                        right: 0;
                        z-index: 1;
                        background: -moz-linear-gradient(left, rgba(244,244,244,0) 0%, rgba(255,255,255,1) 100%);
                        background: -webkit-linear-gradient(left, rgba(244,244,244,0) 0%,rgba(255,255,255,1) 100%);
                        background: linear-gradient(to right, rgba(244,244,244,0) 0%,rgba(255,255,255,1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f4f4f4', endColorstr='#ffffff',GradientType=1 );
                    }
                }
            }
                
        }
    }
}