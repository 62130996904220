#{$app}- {
    &anim {
        &__ {
            &stagger {
                &-{
                    &fade {
                        opacity: 0;

                        &- {
                            &top, &bottom, &left, &right {
                                opacity: 0;
                            }

                            &top {
                                transform: translateY(-10px);
                            }
                            &bottom {
                                transform: translateY(10px);
                            }
                            &left {
                                transform: translateX(10px);
                            }
                            &right {
                                transform: translateX(-10px);
                            }
                        }
                    }

                    &process {
                        &- {
                            &card {
                                &1, &2, &3 {
                                    opacity: 0;
                                    transform: scale(0.8);
                                }
                            }
                            &line, &line2{
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}