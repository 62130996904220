#{$app}- {
    &faq {
        margin-left: -10px;

        &__ {
            &wrap {
                height: 100%;
                max-height: 535px;
                min-height: 445px;
                overflow-y: scroll;
                padding: 10px 10px 0;

                &::-webkit-scrollbar {
                    background-color:rgba($color: #707070, $alpha: 0.18);
                    width: 3px
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba($color: #007A87, $alpha: 1);
                }
            }
            &card {
                background-color: #fff;
                padding: 20px;
                margin-bottom: 20px;
                box-shadow: 0px 0px 14px rgba(138, 149, 158, 0.16);
                cursor: pointer;

                @media (min-width: 768px) {
                    padding: 30px;
                }

                &- {
                    &trigger {
                        background: none;
                        border: none;
                        font-weight: 700;
                        border-radius: 4px;
                        position: relative;
                        // margin-bottom: 30px;
                        display: flex;
                        padding-right: 50px;
        
                        &.btn-header-link:after {
                            content: "\f107";
                            font-family: 'Font Awesome 5 Free';
                            font-weight: 900;
                            position: absolute;
                            right: 0px;

                            @media (min-width: 992px) {
                                right: 20px;
                            }
                        }
        
                    }
                    &answer {
                        font-size: calc(var(--fs-text-sm));

                        @media (min-width: 992px) {
                            font-size: calc(var(--fs-text-sm));
                        }

                        & p {
                            margin-bottom: 20px;
                            
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &content {
                        // padding-bottom: 30px;
                    }
                }
            }

            &cta {
                padding: 30px 0;

                &-- {
                    &blue {
                        background-color: $color-background-blue;
                    }
                }
                &- {
                    &title {
                        font-size: var(--fs-text);
                    }
                }
            }
        }
    }
}