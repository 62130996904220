#{$app}- {
    &list {
        &__ {
            &item {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}