#{$app}- {
    &offering {
        &__ {
            &row {
                margin-bottom: 50px;
                padding: 40px 0 0 0;
                text-align: center;

                @media (min-width: 992px) {
                    padding: 0;    
                    text-align: left;
                }


                &:last-child {
                    margin-bottom: 0px;
                }

                &- {
                    &styled {
                        background: #F8F9F9;
                        // border-radius: 310px;
                        position: relative;
                        // overflow: hidden;
                        padding: 40px 0;
                        text-align: center;

                        @media (min-width: 768px) {
                            border-radius: 30px 310px 310px 30px;
                            padding: 0px;
                            text-align: left;
                        }

                        @media (min-width: 992px) {
                            border-radius: 410px;
                        }

                        & #{$app}-offering__image {
                            position: relative;

                            @media (min-width: 768px) {
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    background: #ffffff;
                                    border-radius: 100%;
                                    transform: scale(1.1);
                                    z-index: 0;
                                }
                            }
                        }
                    }
                }
                // padding: 0px 100px;

                // &::before {
                //     content: "";
                //     position: absolute;
                //     width: 50%;
                //     background-color: #ffffff;
                //     border-radius: 310px 0 0 310px;
                //     right: 0;
                // }
            }
            &image {
                // position: relative;

                // &::before {
                //     content: "";
                //     position: absolute;
                //     width: 100%;
                //     height: 100%;
                //     background: #ffffff;
                //     border-radius: 100%;
                //     transform: scale(1.1);
                //     z-index: 0;
                // }
            }
            &content {
                // padding-left: 15px;
                
                @media (min-width: 992px) {
                    padding: 0px 80px;
                }
            }
            &title {
                font-family: $nunito;
                font-weight: 700;
            }
            &para {
                color: #505050;
                margin-bottom: 20px;
            }
            &modal {
                &- {

                    &left{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        background-color: #FFFDF1;
                    }
                    &title{
                        width: 90%;
                        text-align: center;
                        font-size: 18px;
                        margin-bottom: 50px;
                    }

                    &header{
                        font-size: 26px;
                        // line-height: calc(var(--fs-title) + 0.8rem);
                        font-weight: 500;
                        margin-bottom: 20px;
                        text-transform: uppercase;
                        border-bottom: 3px solid #fec50d;
                    }
                    &body {
                        padding: 30px 0;
                    }
                    &form-control{
                        font-size: 18px;
                        font-weight: 500;
                        // line-height: 1.1;
                        display: inline-flex;
                        gap: 0.5em;
                        color: #707070;
                    }
                    &label{
                        display: block;
                        color: #707070;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        // line-height: 16px;
                        @media (max-width: 480px) {
                            display: block !important;
                        }
                    }
                    &input input {
                        font-size: 18px;
                        padding: 10px 20px;
                        border: none;
                        border: 1px solid #B2B5BB;
                        border-radius: 10px;

                        &::placeholder {
                            color: rgba($color: #000000, $alpha: 0.33);
                        }

                        &:focus {
                            outline: none;
                            // border: none !important;
                            // border-bottom: 1px solid #424241 !important;
                        }
                    }

                    &form-time{
                        margin-right: 10px;
                        padding: 5px 10px;
                        background-color: white;
                        border: 1.5px solid #017a87;
                        cursor: pointer;

                        input[type="radio"]{
                            padding: 0;
                            border: none;
                            border: 0px solid #B2B5BB;
                            border-radius: 0px;
                            -webkit-appearance: none;
                            appearance: none;                            
                            outline: none;
                          }                      
                    }   
                    
                    &form-time:has(> input[type="radio"]:checked) {
                        background-color:#017a87;
                        color: white;
                    }

                    &image {
                        display: inline-block;

                        @media (min-width: 768px) {
                            margin-top: -70px;
                            margin-left: -30px;
                            margin-bottom: -30px;
                        }

                        & img {
                            width: 100%;

                            @media (min-width: 768px) {
                                width: 400px;
                            }
                        }
                    }
                }
            }
        }
    }
}