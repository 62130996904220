#{$app}- {
    &testimonial {
        // width: calc(100% - 200px);
        height: 100%;
        padding: 30px 0 0 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0 , 0.10);
        border: 1px solid #f0f0f0;

        @media (min-width: 1140px) {
            width: 900px;
            padding: 50px 0 0 0;
        }
        @media (min-width: 1200px) {
            width: 950px;
        }
        @media (min-width: 1440px) {
            width: 1200px;
        }

        &__ {
            &slider {
                & .owl-stage-outer .owl-stage {
                    display: flex;
                    flex-wrap: wrap;
                    // overflow: visible !important;
                }
            }
            &quotation {
                top: -10px;
                left: 20px;

                @media (min-width: 1140px) {
                    top: -20px;
                    left: 30px;  
                }

                & img {
                    width: 20% !important;

                    @media (min-width: 1140px) {
                        width: 100% !important;
                    }
                }
            }
            &content {
                width: 100%;
                padding: 30px;

                @media (min-width: 992px) {
                    width: calc(100% - 350px);
                    height: 100%;
                    padding: 10px 10px 40px 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
            &para {
                font-size: calc(var(--fs-text-sm) - 1px);
                line-height: calc(var(--fs-text-sm) - 1px + 0.8rem);

                &::first-letter {
                    font-size: calc(var(--fs-title-medium) + 4px);
                    font-weight: 600;
                }
            }
            &name {
                font-size: var(--fs-subtitle);
                line-height: calc(var(--fs-text) + 0.8rem);
                font-weight: 700;
                color: $color-primary;
            }
            &job {
                color: #7B7B88;
            }
            &image {
                &s {
                    width: 350px;
                    overflow: hidden;

                    @media (min-width: 768px) {
                        overflow: visible;
                    }
                }
                &- {
                    &leaf {
                        left: -50px;
                        bottom: 50px;
                        z-index: 0;

                        & img {
                            width: 70% !important;

                            @media (min-width: 768px) {
                                width: 80% !important;
                            }
                        }
                    }
                    &profile {
                        position: relative;
                    }
                }
            }
        }
    }
}