#{$app}- {
    &section {
        padding: var(--section-spacing) 0;
        &__ {
            &curved {
                background: $color-background-green;
                // padding: 255px 0;
                // background-image: url("/assets/home/section-curved.svg");
                position: relative;
                // padding: 50px 0px;
                // z-index: -2;

                @media (min-width: 768px) {
                    padding: 120px 0px;
                    // margin: -50px 0;
                }

                @media (min-width: 992px) {
                    // padding: 200px 0px;
                    margin: -50px 0 0;
                }

                &- {
                    &top img, &bottom img {

                        @media (min-width: 992px) {
                            height: 60px;
                        }
                    }
                    &top {
                        top: -1px;
                    }
                    &bottom {
                        transform: rotate(180deg);
                        bottom: -1px;
                        z-index: 0;

                        & svg {
                            transform: rotate(180deg);

                            & path {
                                fill: $color-background-green;
                            }
                        }
                    }
                }
            }
            &inwrap {
                position: relative;
                z-index: 1;
            }
            &title {
                &- {
                    &wrap {
                        margin-bottom: var(--title-wrap-spacing);
                    }
                    &paddingx {
                        @media (min-width: 1100px) {
                            padding: 0 100px;
                        }
                    }
                }
            }
            &banner {
                background-position: bottom;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #e6f4fc;
                position: relative;

                @media (min-width: 1200px) {
                    min-height: 650px;
                }
            }
        }
        &-- {
            &green {
                background: $color-background-green;
            }
            &blue {
                background: $color-background-blue;
            }
        }
    }
}