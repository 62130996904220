#{$app}- {
    &leadership {
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
        margin-top: 105px;
        overflow: hidden;

        &__ {
            // &top {
            //     background-color: $color-primary;
            //     text-align: center;
            //     color: #ffffff;
            //     padding-top: 105px;

            //     &- {
            //         &content {
            //             padding: 25px;
            //         }
            //     }
            // }

            // &image {
            //     width: 210px;
            //     height: 210px;
            //     border: 5px solid #ffffff;
            //     background-color: lightgrey;
            //     border-radius: 100%;
            //     position: absolute;
            //     top: -105px;
            //     left: 0;
            //     right: 0;
            //     margin: 0 auto;
            // }

            // &name {
            //     font-size: 28px;
            //     font-weight: 600;
            // }
            // &role {
            //     font-size: var(--fs-text-sm);
            // }
            // &para {
            //     padding: 60px 30px 40px 100px;
            // }
            // &quotation {
            //     top: 20px;
            //     left: 20px;
            // }
            // &social {
            //     &-{
            //         &wrap {
            //             padding: 0px 50px;
            //         }
            //         &link {
            //             padding: 30px 0;
            //             border-top: 1px solid rgba($color: #707070, $alpha: 0.3);
            //         }
            //     }
            // }

            &slider {
                // padding-top: 50px;

                & .owl-stage-outer {
                    overflow: visible;
                }
                & .owl-stage {
                    display: flex;
                    align-items: flex-end;
                }

                @media (min-width: 1400px) {
                    padding-top: 60px;
                }
                    
            }
            &slide {
                background: #E6F4FC;
                border-radius: 4px;
                box-shadow: 0px 3px 6px rgba(138, 149, 158, 0.16);
            }
            &content {
                width: 100%;
                padding: 20px 15px;

                @media (min-width: 768px) {
                    width: 63%;
                    padding: 40px 50px;
                }

                @media (min-width: 992px) {
                    padding: 80px 100px;
                }
            }
            &images {

                @media (min-width: 768px) {
                    width: 37%;
                }
            }
            &name {
                font-weight: 600;
            }
            &para {
                color: #313131;
                font-weight: 500;
            }
            &image-profile {

                @media (min-width: 768px) {
                    position: absolute;
                    bottom: 0;
                    right: 0;

                }
            }
        }
    }
}