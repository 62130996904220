#{$app}- {
    &mentor {
        &__ {
            &package {
                &- {
                    &card {
                        padding: 2.2rem 2rem 1.8rem;
                        border-radius: 13px;
                        background: #fff;
                        border: 0.6px solid #d1d1d1;

                        &-- {
                            &blue {
                                background: #E6F4FC;
                                color: #313131;

                                & #{$app}-mentor_package-title {
                                    font-family: $nunito;

                                    &:first-child {
                                        font-size: var(--fs-text);
                                    }
                                    &:nth-child(2), &:nth-child(3) {
                                        font-size: calc(var(--fs-title));
                                        line-height: calc(var(--fs-title) + 0.8rem);
                                        font-weight: 600;

                                        @media (min-width: 768px) {
                                            font-size: calc(var(--fs-title) - 5px);
                                        }
                                    }
                                    &:nth-child(3) {
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                    }
                    &badge {
                        background-color: #007A87;
                        color: #ffffff;
                        padding: 5px 20px 5px 30px;
                        display: inline-block;
                        position: absolute;
                        right: -15px;
                        top: 40px;
                        font-size: 10px;

                        &::before, &::after {
                            content: "";
                            position: absolute;
                        }
                        &::before {
                            border-bottom: 13px solid transparent;
                            border-top: 13px solid transparent;
                            border-left: 14px solid white;
                            left: 0;
                            top: 0px;
                        }
                        &::after {
                            border-bottom: 13px solid transparent;
                            border-top: 0px solid transparent;
                            border-left: 15px solid #007a87;
                            right: 0px;
                            bottom: -13px;
                        }
                    }
                    &title {
                        font-size: var(--fs-subtitle);
                        line-height: calc(var(--fs-subtitle) + 0.8rem);
                        font-family: $nunito;
                        font-weight: 600;
                        color: #313131;
                    }
                    &item {
                        font-size: calc(var(--fs-text-sm) + 2px);
                        line-height: calc(var(--fs-text-sm) + 2px + 0.8rem);
                        margin-bottom: 12px;

                        @media (min-width: 1140px) {
                            font-size: calc(var(--fs-text-sm));
                            line-height: calc(var(--fs-text-sm) + 0.8rem);
                        }

                        & br {
                            @media (max-width: 1100px) {
                                display: none;
                            }
                        }
                    }

                    &bottom {
                        padding: 3rem 0 1rem 0;
                        border-top: 1px dashed #7A7A7A;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}