#{$app}- {
    &card {
        // width: 500px;
        border-radius: 15px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
        margin-bottom: 30px;
        border: 1px solid $color-border-color;

        &__ {
            &sharp {
                padding: 10px;
                border-radius: 8px;
                border: 1px solid rgba($color: #707070, $alpha: 0.2);

                #{$app}-card__image {
                    border-radius: 8px 8px 0 0;
                }
            }
            &slider {
                & .owl-stage-outer {
                    padding-left: 18px;
                }
            }
            &left {
                width: 100%;
                padding: 0 15px;

                @media (min-width: 768px) {
                    // width: auto;
                    padding: 0 10px;
                }

                @media (min-width: 1170px) {
                    width: calc(570px - 8.333%);
                }

                @media (min-width: 1320px) {
                    width: calc(665px - 8.333%);
                    padding: 0px;
                }
                @media (min-width: 1600px) {
                    width: calc(745px - 8.333%);
                }
            }
            &image {
                height: 18.8rem;    
                background-color: lightgrey;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                padding: 20px;

                @media (min-width: 768px) {
                    padding: 2rem 0 0 2rem;
                }

                @media (min-width: 992px) {
                    height: 18.8rem;    
                }
                // @media (min-width: 1400px) {
                //     height: rem;    
                // }
            }
            &catagory {
                font-size: calc(var(--fs-text-sm) - 1px);
                line-height: calc(var(--fs-text-sm) - 1px + 0.8rem);
                background: $color-primary;
                padding: 0.4rem 1.6rem;
                border-radius: 5px;
                display: inline-block;
                color: #FFFFFF;

                &- {
                    &event {
                        border-radius: 0;
                    }
                }

                & a {
                    color: #FFFFFF;
                    cursor: unset;
                }
            }
            &content {
                padding: 15px 20px;

                @media (min-width: 768px) {
                    padding: 2rem 3rem;
                }
            }
            &date-time {
                color: $color-primary;
                font-size: calc(var(--fs-text-sm));
                line-height: calc(var(--fs-text-sm) + 0.8rem);
                font-weight: 600;

                @media (min-width: 992px) {
                    font-size: calc(var(--fs-text-sm) - 1px);
                    line-height: calc(var(--fs-text-sm) - 1px + 0.8rem);
                }
            }
            &date {
                width: 75%;
                // width: 100%;

                @media (min-width: 992px) {
                    width: 75%;
                }

                &- {
                    &info {
                        font-size: calc(var(--fs-text-sm) - 2px);
                        display: inline-block;
                        padding: 8px 18px;
                        color: $color-primary;
                        background-color: rgba($color: #007A87, $alpha: 0.2);
                        border-radius: 5px;

                        & a {
                            color: $color-primary;
                        }
                    }
                }
            }
            &time {
                width: 25%;
                margin-top: 10px;

                @media (min-width: 992px) {
                    width: 25%;
                    margin-top: 0;
                }
            }
            &status {
                &-- {
                    &red {
                        color: #FC1D1D;
                    }
                }
            }
            &name {
                height: 4rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: var(--fs-text);
                font-weight: 600;
                color: #000000;

                // @media (min-width: 768px) {
                //     height: 64px;
                // }
                
            }
            &event {
                &- {
                    &image {
                        height: 100%;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        z-index: 0;
                        object-fit: contain;
                        object-position: center;
                        overflow: hidden;

                        &-- {
                            &ghost {
                                object-fit: cover;
                                width: 100%;
                                filter: blur(20px);

                                &.home {
                                    transform: scale(1.5);
                                }
                            }
                        }
                    }
                }
            }
            &blog {
                &- {
                    &image {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
            &para {
                height: 5.3rem;
                font-size: calc(var(--fs-text-sm));
                font-family: $nunito;
                color: #000000;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-weight: 500;

                // @media (min-width: 992px) {
                //     font-size: var(--fs-text-sm);
                // }

                &- {
                    &event {
                        -webkit-line-clamp: 3;

                        @media (min-width: 768px) {
                            height: 5.3rem;
                        }

                    }
                }

                &-- {
                    &grey {
                        color: rgba($color: #212121, $alpha: 0.4);
                    }
                }
            }
        }
    }
}