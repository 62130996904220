.new-banner {
  min-height: 450px;
  img {
    min-height: 450px;
    object-fit: cover;
  }
}

.home-banner-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  h3 {
    color: #fff;
    font-size: 43px;
  }
  p {
    font-size: 24px;
    color: #fff;
  }
}

@media (max-width: 767.98px) {
  .home-banner-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    h3 {
      color: #fff;
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: #fff;
    }
  }
}

.fz-24 {
  font-size: 24px;
}
.s-color {
  color: #111111;
}

.p-color {
  color: #007a87;
}



.primary-gradient-card {
  background: radial-gradient(
      122.81% 731.25% at 86.24% 114.56%,
      rgba(19, 184, 154, 0.3) 0%,
      rgba(88, 135, 255, 0) 100%
    ),
    linear-gradient(180deg, #014961 0%, #082550 100%);
  border-radius: 18px;
  padding: 90px 20px 20px 20px;
  position: relative;
  height: 100%;
  display: block;
  .float-icon {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -75px;
    left: calc(50% - 75px);

    box-shadow: 0px 12px 55px rgba(8, 93, 111, 0.37);
    img {
      width: 70px;
      height: 70px;
    }
  }

  h5 {
    color: #fff;
  }
  p {
    font-size: 16px;
    color: #f0fbf7;
  }
}

.row-top {
  margin-top: 125px;
}

.featured-member {
  margin: 50px 0;
  padding: 150px 0;
  background-size: auto 100%;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  // backgrou nd-image: url("../../../../build/assets/images/about-img.jpg");
}

.featured-member-card {
  position: relative;
  .primary-txt {
    font-size: var(--fs-text);
    color: #007a87;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .secondary-txt {
    font-size: 14px;
    color: #111111;
  }
  .member-company {
    position: absolute;
    img{
      height: 25px;
      width: auto;
    }
    right: 25px;
    top: 10px;
  }
}

@media (max-width: 767.98px) {
  .featured-member-card {
    text-align: center;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.section-2 {
  margin: 50px 0;
  column-count: 5;
}

.learn-wrap {
  position: relative;
  .float-center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 26px;
    width: 100%;
    height: 100%;
    top: 0;
  }
}

.section-3 {
  margin: 25px 0;
  // column-count: 3;
  p {
    font-weight: 600;
    color: #111111;
    margin-bottom: 5px;
  }

  .s-text {
    color: #525252;
    font-size: 12px;
    font-weight: 400;
  }
}

.featured-jobs {
  @extend .featured-member;
  background-size: 100% 100%;
  padding: 50px 0;
}

.row-top-100 {
  margin-top: 120px;
  margin-bottom: 120px;
}
.secondary-gradient-card {
  @extend .primary-gradient-card;
  background: #007a87;

  h5 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
  .float-icon {
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}

.section-4 {
  max-width: 1000px;
  margin: 50px auto;
 
  p {
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
  }
  img {
    border-radius: 28px;
  }
  .active1 {
    border: 7px solid #007a87;
    border-radius: 42px;
    width: 210px;
    border-radius: 35px;
    height: 213px;
    transition: width .25s,;
    img{
      border-radius: 24px;
      height: 200px;
    }
  }

  .mentor-card {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.section-5 {
  max-width: 800px;
  margin: 50px auto;
  .left-quote {
    width: 250px;
  }
  .right-quote {
    // height: 150px;
    width: 200px;
  }
}

.footer {
  padding: 25px 0;
  background-color: #007a87;
  color: #fff;
  .logo {
    margin-bottom: 25px;
  }
  p {
    color: #fff;
  }
}

.social-icons {
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  div {
    border: 1px solid #fff;
    border-radius: 7px;
    margin: 10px;
    height: 36px;
    padding: 0 7px;
  }
}

.sub-input {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  input {
    border: none;
    outline: none;
  }
}

.section-3-inner {
  img {
    margin-bottom: 10px;
    width: 100%;
    min-height: 250px;
    object-fit: cover;
    height: 250px;
  }
  title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.testimonial {
  // width: 156px;
  height: 150px;
}

.button-prev-next {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 23px rgba(24, 92, 111, 0.111041);
}

.floated-right {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.floated-left {
  position: absolute;
  left: 0;
  cursor: pointer;
}

.test-card {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-left: 240px;
  scroll-behavior: smooth;
}
