#{$app}- {
    &modal {
        &__ {
            &heading {
                &- {
                    &text {
                        &-- {
                            &plain {
                                font-size: var(--fs-text-sm) !important;
                                color: #000000 !important;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            &btn {
                &- {
                    &close {
                        position: absolute;
                        right: 0px;
                        color: #bcbcbc !important;
                        opacity: 1 !important;
                        background: white !important;
                        width: 30px;
                        height: 30px;
                        border-radius: 100px;
                        z-index: 2;

                        @media (min-width: 768px) {
                            right: -40px;
                        }
                    }
                }
            }
            &trails {
                height: 100%;
                margin: 0 !important;

                & .modal-content {
                    @media (max-width: 767px) {
                        height: 100%;
                        overflow-y: scroll;
                    }
                }

                @media (min-width: 768px) {
                    top: 100px;
                    margin: 0 auto !important;
                }
                @media (min-width: 992px) {
                    max-width: 440px !important;
                    margin: 10px;
                }
                @media (min-width: 1024px) {
                    max-width: 910px !important;
                }
                @media (min-width: 1200px) {
                    max-width: 1070px !important;
                }
                @media (min-width: 1400px) {
                    max-width: 1150px !important;
                }

                & .modal-body {
                    height: 100%;

                    @media (min-width: 768px) {
                        height: auto;
                    }
                }

                &- {
                    &title, &para {
                        & br {
                            display: none;
                        }

                        @media (min-width: 300px) {
                            text-align: center;
                            font-size: 2rem;
                            line-height: calc(2rem + 0.8rem);
                        }
                    }
                }
            }
        }
    }
}




.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: none;
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    // border-radius: 100%;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #f6f6f6;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #f6f6f6;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
}

.modal-footer> :not(:first-child) {
    margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }
    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}


.react-responsive-modal-modal {
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
}

#email-modal .modal-content.email-modal-content,
#news-modal .modal-content.email-modal-content {
    border-radius: 0px;
    margin: 0 auto;
    border-color: "#fff";
    @media (min-width: 768px) {
        width: 480px;
    }
}

#email-modal .modal-content.email-modal-content .form-close-btn,
#news-modal .modal-content.email-modal-content .form-close-btn {
    position: absolute;
    right: 45px;
    top: 27px;
}

#email-modal .modal-content.email-modal-content .form-close-btn .close,
#news-modal .modal-content.email-modal-content .form-close-btn .close {
    font-size: 30px;
    cursor: pointer;
    z-index: 1;
}

.email-wrap .email-text-content,
.email-wrap .news-text-content,
.news-wrap .email-text-content,
.news-wrap .news-text-content {
    padding: 40px;
    // padding-bottom: 0px;
}

.email-wrap .email-text-content .email-img,
.email-wrap .email-text-content .news-img,
.email-wrap .news-text-content .email-img,
.email-wrap .news-text-content .news-img,
.news-wrap .email-text-content .email-img,
.news-wrap .email-text-content .news-img,
.news-wrap .news-text-content .email-img,
.news-wrap .news-text-content .news-img {
    text-align: center;
}

.email-wrap .email-text-content .email-img img,
.email-wrap .email-text-content .news-img img,
.email-wrap .news-text-content .email-img img,
.email-wrap .news-text-content .news-img img,
.news-wrap .email-text-content .email-img img,
.news-wrap .email-text-content .news-img img,
.news-wrap .news-text-content .email-img img,
.news-wrap .news-text-content .news-img img {
    display: inline-block;
    margin-bottom: 30px;
}

.email-wrap .email-text-content .email-sec-title,
.email-wrap .email-text-content .news-sec-title,
.email-wrap .news-text-content .email-sec-title,
.email-wrap .news-text-content .news-sec-title,
.news-wrap .email-text-content .email-sec-title,
.news-wrap .email-text-content .news-sec-title,
.news-wrap .news-text-content .email-sec-title,
.news-wrap .news-text-content .news-sec-title {
    text-align: center;
}

.email-wrap .email-text-content .email-sec-title h5,
.email-wrap .email-text-content .news-sec-title h5,
.email-wrap .news-text-content .email-sec-title h5,
.email-wrap .news-text-content .news-sec-title h5,
.news-wrap .email-text-content .email-sec-title h5,
.news-wrap .email-text-content .news-sec-title h5,
.news-wrap .news-text-content .email-sec-title h5,
.news-wrap .news-text-content .news-sec-title h5 {
    font-size: 18px;
    margin-bottom: 20px;
}

.email-wrap .email-text-content .email-sec-title p,
.email-wrap .email-text-content .news-sec-title p,
.email-wrap .news-text-content .email-sec-title p,
.email-wrap .news-text-content .news-sec-title p,
.news-wrap .email-text-content .email-sec-title p,
.news-wrap .email-text-content .news-sec-title p,
.news-wrap .news-text-content .email-sec-title p,
.news-wrap .news-text-content .news-sec-title p {
    margin-bottom: 30px;
}

.email-wrap .email-text-content .btn-wrap .btn,
.email-wrap .news-text-content .btn-wrap .btn,
.news-wrap .email-text-content .btn-wrap .btn,
.news-wrap .news-text-content .btn-wrap .btn {
    height: 60px;
    font-size: 20px;
    margin-top: 16px;
}
