#{$app}- {
    &button {
        font-size:var(--fs-text-sm);
        line-height: calc(var(--fs-text-sm) + 1rem);
        padding: 0.6rem 4.6rem 0.6rem 3.6rem;
        background: $color-tertiary;
        color: #313131;
        border: 1px solid $color-tertiary;
        border-bottom-right-radius: 30px;
        text-align: center;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;
        // overflow: hidden;

        // @media (min-width: 992px) {
        //     font-size: calc(var(--fs-text-sm));
        //     line-height: calc(var(--fs-text-sm) + 1rem);
        // }

        // &::before {
        //     content: "";
        //     position: absolute;
        //     width: 20px;
        //     height: 20px;
        //     border-radius: 100px;
        //     background: #000000;
        //     opacity: 0.05;
        //     right: 0;
        //     left: 0;
        //     top: 0;
        //     bottom: 0;
        //     margin: auto;
        //     transform: scale(0);
        //     transition: all 0.2s ease;
        //     z-index: 0;
        // }

        &::selection {
            position: relative;
        }

        &:hover {
            color: #313131;
            padding-left: 4.0rem;
            padding-right: 5.0rem;
            // background: none;
            // transform: scale(11);
        }

        &.hover-color {
            &:hover {
                background-color: #f4b61c;
                padding:  0.6rem 4.6rem 0.6rem 3.6rem !important;
            }
        }

        &-- {
            &lined {
                background: none;

                &:hover {
                    background: $color-tertiary;
                }
            }
        }

        &__ {
            &center-align {
                width: 15.4rem;
                margin: 0 auto;
                transform: translateX(1rem);
            }
            &disabled {
                opacity: 0.4;
            }
            &radius {
                border-radius: 10px !important;
            }
            &no-radius {
                border-radius: 0px;

                &:hover {
                    background-color: #f4b61c;
                    padding:  0.6rem 4.6rem 0.6rem 3.6rem !important;
                }
            }
            &plain {
                &- {
                    &arrow {
                        background: none;
                        border: 0;
                        padding: 0px 0;

                        & svg {
                            transition: all 0.2s ease;
                        }

                        &:hover {
                            padding-right: 0px;

                            & svg {
                                transform: translateX(5px);
                            }
                        }
                    }
                }
            }

            &video {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                cursor: pointer;

                &- {
                    &circle {
                        width: 90px;
                        height: 90px;
                        padding: 10px;
                        position: relative;

                        @media (min-width: 768px) {
                            width: 70px;
                            height: 70px;
                        }
                        @media (min-width: 992px) {
                            width: 100px;
                            height: 100px;
                        }
                    }
                    &icon {
                        width: 100%;
                        height: 100%;
                        background: $color-tertiary;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.2s ease;

                        &:hover {
                            background: #f4b61c !important;
                        }

                        &::before, &::after {
                            content: "";
                            position: absolute;
                            animation: animcircle 1s linear 0s infinite;
                            z-index: -1;
                        }
                        &::before {
                            width: 100%;
                            height: 100%;
                            border: 1px solid $color-tertiary;
                            border-radius: 100%;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            animation-delay: 0.5s;
                        }
                        &::after {
                            width: 100%;
                            height: 100%;
                            border: 1px solid $color-tertiary;
                            border-radius: 100%;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            transform: scale(0.8);
                        }

                        & .arrow {
                            border-top: 15px solid transparent;
                            border-left: 15px solid white;
                            border-bottom: 15px solid transparent;

                            @media (min-width: 768px) {
                                border-top: 10px solid transparent;
                                border-left: 10px solid white;
                                border-bottom: 10px solid transparent;
                            }

                            @media (min-width: 992px) {
                                border-top: 20px solid transparent;
                                border-left: 20px solid white;
                                border-bottom: 20px solid transparent;
                            }
                        }
                    }

                    &text {
                        width: calc(100% - 90px);
                        padding-left: 15px;

                        @media (min-width: 768px) {
                            width: calc(100% - 70px);
                        }

                        @media (min-width: 992px) {
                            width: calc(100% - 100px);
                        }
                    }
                }
            }

            &scroll-top {
                color: $color-primary;
            }
        }
    }
}

@keyframes animcircle {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}