#{$app}- {
    &mentor {
        &__ {
            &testimonial {

                & .owl-stage-outer {
                    @media (min-width: 768px) {
                        padding: 25px;
                    }

                    & .owl-stage {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                & .owl-item {
                    // height: 100%;
                }

                & .owl-item.active {

                    & #{$app}-mentor__testimonial {
                        &- {
                            &slide {
                                background: $color-primary;
                                color: #ffffff !important;

                                @media (min-width: 768px) {
                                    transform: scale(1.05);
                                }
                            }
                            &name {
                                color: #ffffff !important;
                            }
                            &icon path {
                                fill: #ffffff;
                            }
                        }
                    }

                    & + div {
                        & #{$app}-mentor__testimonial {
                            &- {
                                &slide {
                                    background: #ffffff !important;
                                    color: #313131 !important;
                                    transform: scale(1) !important;
                                }
                                &name {
                                    color: #313131 !important;
                                }
                                &icon path {
                                    fill: $color-primary;
                                }
                            }
                        }
                    }
                }
                & .owl-dots {
                    text-align: left !important;
                }

                &- {
                    &slide {
                        height: 100%;
                        padding: 30px;
                        background: #ffffff;
                        font-family: $nunito;
                        transition: all 0.2s ease;

                        @media (min-width: 768px) {
                            width: 460px;
                        }

                        &.active-card{
                            width: 560px;
                            background: $color-primary;
                            color: #ffffff !important;

                            & .sh-mentor__testimonial-name{
                                color: #ffffff !important;
                            }

                            & .sh-mentor__testimonial-profile{
                                width: 270px;
                                height: 190px;
                            }
                        }                        
                    }
                    &para {
                        font-size: calc(var(--fs-text-sm) - 1px);
                        line-height: calc(var(--fs-text-sm) - 1px + 0.8rem);

                        // @media (min-width: 1140px) {
                        //     font-size: var(--fs-text);
                        //     line-height: calc(var(--fs-text) + 0.8rem);
                        // }
                    }
                    &profile {
                        width: 80px;
                        height: 80px;
                        background: lightgrey;
                        border-radius: 100px;
                        overflow: hidden;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    &content {
                        width: calc(100% - 80px);
                        padding-left: 10px;
                    }
                    &name {
                        font-weight: 600;
                    }
                    &role {
                        font-size: var(--fs-text-sm);
                        line-height: calc(var(--fs-text-sm) + 0.8rem);
                        font-weight: 300;

                        @media (min-width: 992px) {
                            font-size: calc(var(--fs-text-sm) - 2px);
                            line-height: calc(var(--fs-text-sm) - 2px + 0.8rem);
                        }
                    }
                }
            }
        }
    }
}