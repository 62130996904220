#{$app}- {
    &subscription {
        background-color: rgba($color: $color-primary, $alpha: 0.22);
        background-size: cover;
        background-position: center;

        &__ {
            &ebook {
                height: 100%;
                padding: 0 15px 40px 15px;
                // border-bottom-right-radius: 300px;
                position: relative;
                overflow: hidden;
                background-color: transparent !important;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: $color-tertiary, $alpha: 0.5);

                @media (min-width: 768px) {
                    padding: 0 40px;
                    border-bottom-right-radius: 50px;
                }
                }

                @media (min-width: 992px) {
                    padding: 0 80px;
                    border-bottom-right-radius: 150px;
                }
            }
            &wrap {
            }
            &content {
                padding: var(--section-spacing) 0;

                &-{
                    &ebook {
                        padding: var(--section-spacing) 0 calc( var(--section-spacing) / 2);

                        @media (min-width: 992px) {
                            padding: var(--section-spacing) 0;
                        }
                    }
                }
            }
            &form {
                &- {
                    &input {
                        width: 100%;
                        font-size: var(--fs-text-sm);
                        line-height: calc(var(--fs-text-sm) + 1rem);
                        border: 0;
                        padding-left: 15px;
                        outline: none;
                        border-radius: 4px;
                        padding: 14px;
                        margin-bottom: 10px;

                        @media (min-width: 768px) {
                            width: calc(100% - 235px);
                            padding: 10px 25px;
                            border-radius: 4px 0px 0px 4px;
                            margin-bottom: 0;
                        }

                        @media (min-width: 992px) {
                            font-size: calc(var(--fs-text-sm) - 2px);
                            line-height: calc(var(--fs-text-sm) - 2px + 1rem);
                        }

                        &-- {
                            &lined {
                                border: 1px solid #ffffff;
                                background: none;
                                color: #ffffff;

                                &::placeholder {
                                    color: #ffffff;
                                }

                                @media (min-width: 768px) {
                                    border-right: none;
                                }
                            }
                        }
                    }
                    &btn {                        
                        @media (min-width: 768px) {
                            width: 235px;
                        }
                    }
                }
            }
        }
    }
}